@import '../../../../scss/theme-bootstrap';

.basic-carousel-formatter {
  position: relative;
  margin-bottom: 30px;
  @if $cr22 {
    .slick-slider {
      padding: 0;
    }
  }
  &.content-container {
    max-width: $content-block-max-width;
    margin: 0 auto;
  }
  @include breakpoint($large-up) {
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  @include breakpoint(1150px) {
    .slick-prev {
      left: -40px;
    }
    .slick-next {
      right: -40px;
    }
  }
  @if $cr22 {
    .content-block-formatter__item & {
      margin: 0 -20px;
      @include breakpoint($landscape-up) {
        margin: 0;
        padding-#{$ldirection}: 35px;
        padding-#{$rdirection}: 35px;
      }
    }
  }
}

.basic-carousel__slide {
  visibility: hidden; // Prevent FOUC
  &:first-child {
    visibility: visible;
  }
  .slick-initialized & {
    visibility: visible;
  }
  @if $cr22 {
    margin-#{$rdirection}: 20px;
    @include breakpoint($medium-up) {
      margin-#{$rdirection}: 0;
    }
    &.slick-active {
      .content-block-tout__text {
        padding-#{$ldirection}: 20px;
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 0;
        }
      }
    }
  }
}

//
// Content block styles
//
.basic-carousel-formatter--no-peeking {
  .basic-carousel {
    padding: 0;
  }
  .slick-list {
    padding: 0;
  }
  .slick-slide {
    margin-right: 0;
  }
  .slick-arrow:before {
    font-size: 16px;
  }
}

.basic-carousel .content-block--split-width {
  padding: 0 8%;
}

.basic-carousel-formatter--image-dots {
  // Mobile down move dots
  @include breakpoint($small-down) {
    .carousel-dots {
      display: none;
    }
    .basic-carousel__slide .carousel-dots {
      display: block;
    }
  }
}

.slick-slide {
  @include breakpoint($landscape-up) {
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.slick-active {
  @include breakpoint($landscape-up) {
    opacity: 1;
  }
}

.basic-carousel__slide {
  @include breakpoint($landscape-up) {
    .basic-carousel--3 &,
    .basic-carousel--4 & {
      margin: 0 5px;
    }
  }
}
